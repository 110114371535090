@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,500&family=Poppins:wght@400;500&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  color: #181818;
  border: none;
  background: none;
}

body {
  background-color: #23202d;
  padding: 45px;
  padding-top: 0 !important;
  box-sizing: border-box;
  min-width: 768px;
}

@media screen and (max-width: 992px) {
  body {
    padding: 30px;
  }
}

@media screen and (max-width: 768px) {
  body {
    padding: 20px;
  }
}
