@keyframes refreshButtonAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

.chart {
  width: 100%;
  height: 500px;
  background: #fff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  padding-right: 25px;
  box-sizing: border-box;
}

.chart__header {
  width: 100%;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr auto auto;
  padding: 25px 0 25px 25px;
  box-sizing: border-box;
}

.chart__title {
  font-size: 23px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chart__refresh-btn {
  cursor: pointer;
}

.chart__refresh-btn,
.chart__refresh-btn-icon {
  height: auto;
  width: 24px;
  display: block;
}

.chart__refresh-btn--active .chart__refresh-btn-icon {
  animation: refreshButtonAnimation 1s ease-in-out;
}

.dropdown-menu {
  width: 135px;
  height: 35px;
  position: relative;
  z-index: 2;
}

.dropdown-menu__inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  border-radius: 1000px;
  cursor: pointer;
  column-gap: 10px;
}

.dropdown-menu__selected-option {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-menu__arrow {
  width: 13px;
  height: auto;
  display: block;
  transform: rotate(0deg);
  transition: 0.25s transform;
}

.dropdown-menu:not(.dropdown-menu--show) .dropdown-menu__options {
  display: none;
}

.dropdown-menu--show .dropdown-menu__arrow {
  transform: rotate(180deg);
}

.dropdown-menu__options {
  width: 100%;
  height: auto;
  position: absolute;
  top: 100%;
  transform: translateY(5px);
  left: 0;
  background: #fff;
  border: 1px solid #e4e4e4;
  display: block;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown-menu__option {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-menu__option:hover {
  color: #fff;
  background: #6C61FB;
}

.chart__value,
.chart__date {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.4;
  fill: #181818;
}

.chart__hour {
  font-size: 14px;
  font-weight: 400;
  display: block;
}