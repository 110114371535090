.header {
  width: 100%;
  height: auto;
  padding: 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo__icon {
  height: 48px;
  width: auto;
}

.logo__title {
  font-size: 26px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}