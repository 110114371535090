.charts {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

@media screen and (max-width: 1280px) {
  .charts {
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 992px) {
  .charts {
    gap: 25px;
  }
}
